.ant-checkbox .ant-checkbox-inner {
  background: transparent !important;
  border-radius: 50%;
  height: 18px;
  width: 18px;
}

.ant-checkbox-checked {
  &::after {
    content: '';
    border: none;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  border-radius: 50%;
  height: 18px;
  width: 18px;

  &::after {
    border: none;
  }
}

li.ant-list-item:has(.ant-checkbox-checked) {
  background-color: #c78f1110 !important;
}

.ant-list-item-meta-description {
  font-size: 13px;
  word-break: break-word;
}

.ant-radio-button-wrapper {
  color: #fff !important;
}

.right-info {
  .ant-card {
    background-color: $Cool_Black;
  }
  .ant-card-head-title {
    color: $Pure_White;
  }
  .ant-form-item-row {
    margin-top: 0.5rem !important;
  }
  .ant-form-item-label {
    padding: 0 !important;
  }
}

.social {
  .social-svg-icon {
    fill: $Pure_White !important;
  }
  .social-icon.sharethis {
    .social-container {
      background-image: url('../../../../../public/assets/link.png');
      background-repeat: no-repeat;
      background-size: contain;
      .social-svg {
        display: none !important;
        opacity: 0 !important;
        visibility: hidden !important;
      }
    }
  }
}

.user_invite {
  .body_layout {
    @media (max-width: 600px) {
      padding: 0 !important;
    }
  }
  h1 {
    position: sticky;
    padding: 1rem 0 1.5rem 0;
    font-size: 36px;
    font-weight: 400 !important;
    line-height: 43px;
    letter-spacing: -1px;
    color: $Mindaro_Yellow-Green;
    top: 112.8px;
    background: $Cool_Black;
    z-index: 10;
  }

  .invite_form {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    padding: 1.5rem 0;
    @media (max-width: 600px) {
      flex-direction: column;
    }
    .title_wrapper {
      flex: 1 0 45%;
      h2 {
        font-size: 22px;
      }
    }
    .form_wrapper {
      flex: 1 0 45%;
      h2 {
        margin-bottom: 0.75rem;
        font-size: 22px;
      }
    }
    .form_input {
      display: flex;
      gap: 1rem;

      input {
        max-width: 600px;
        width: 100%;
      }
      .ant-btn-primary {
        @media (max-width: 600px) {
          width: fit-content !important;
        }
      }
    }
  }
}

.invite_table {
  @media (max-width: 600px) {
    tbody {
      tr {
        td {
          &:first-child {
            max-width: 180px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          &:nth-child(2) {
            min-width: 135px;
          }
          &:nth-child(3) {
            min-width: 100px;
          }
          &:last-child {
            display: flex;
            .ant-btn-primary {
              width: fit-content !important;
              padding: 4px 14px !important;
            }
            .ant-btn-default {
              width: fit-content !important;
              padding: 4px 14px !important;
            }
          }
        }
      }
    }
  }
}
