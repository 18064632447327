.login_page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 490px;
  min-height: 100dvh;
  margin: 0 0.75rem;
  @media (max-width: 600px) {
    margin: auto;
    min-width: auto;
  }
  .header_text {
    text-align: center;
    .logo_asset {
      margin: 2rem 0;
      width: 9rem;
    }
    h2 {
      color: #fff;
      line-height: 1.5;
      margin-bottom: 1.6rem;
      padding: 0 1rem;
      font-size: 1.25rem;
      font-weight: 400;
      text-transform: uppercase;
    }
  }

  .form_input {
    padding: $size 4rem;
    border-radius: 10px 10px 0 0;
    width: 490px;
    max-height: 540px;
    text-align: center;
    position: relative;
    margin-bottom: -16px;

    @media (max-width: 576px) {
      width: 100%;
      padding: 1rem;
    }

    input,
    input:hover,
    input:focus,
    input::placeholder,
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      background-color: $light_Black !important;
      // border-color: #b9b9b9;
      color: $input_placeholder;
      font-size: $size;
      caret-color: $Pure_White;
    }

    .ant-input-affix-wrapper,
    .ant-input-affix-wrapper:hover,
    .ant-input-affix-wrapper:focus {
      background-color: $light_Black !important;
      // border-color: $border-color;
    }
    /* Autofill background color */
    .ant-input:-webkit-autofill {
      background-color: transparent !important;
      box-shadow: 0 0 0px 1000px $light_Black inset !important;
      -webkit-text-fill-color: $Pure_White !important;
    }

    .ant-input:-webkit-autofill:hover,
    .textbant-inputox:-webkit-autofill:focus,
    .ant-input:-webkit-autofill:active {
      background-color: transparent !important;
      -webkit-box-shadow: 0 0 0px 1000px $light_Black inset !important;
      box-shadow: 0 0 0px 1000px $light_Black inset !important;
      -webkit-text-fill-color: $Pure_White !important;
    }

    .ant-input:-moz-autofill {
      background-color: transparent !important;
      -moz-box-shadow: 0 0 0px 1000px $light_Black inset !important;
      box-shadow: 0 0 0px 1000px $light_Black inset !important;
      -moz-text-fill-color: $Pure_White !important;
    }

    .ant-input:-moz-autofill:hover,
    .ant-input:-moz-autofill:focus,
    .ant-input:-moz-autofill:active {
      background-color: transparent !important;
      -moz-box-shadow: 0 0 0px 1000px $light_Black inset !important;
      box-shadow: 0 0 0px 1000px $light_Black inset !important;
      -moz-text-fill-color: $Pure_White !important;
    }
    .ant-form-item {
      margin-bottom: 1.438rem !important;
    }

    .btn_primary {
      color: $Cool_Black;
      padding: 0rem 2.5rem;
      border: none;
      border-radius: 4px;
      margin-top: 1rem;
      height: 2.5rem;
      background: $Mindaro_Yellow-Green !important;
    }

    .btn-link {
      font-size: 1rem;
      color: $Sand;
      font-weight: 400;
      text-align: center;
      line-height: 1;
      cursor: pointer;

      &::first-letter {
        text-transform: capitalize;
      }
    }
  }

  .form-title {
    font-size: 1.25rem;
    color: $Pure_White;
    font-weight: 400;
    text-align: center;
    line-height: 1;
    padding: $size 0;
    text-transform: uppercase;
  }

  .text-color {
    color: $Pure_White;
    font-size: 14px;
  }
  .invitee_text {
    padding-bottom: 1rem;
    text-align: center;
    @media (max-width: 600px) {
      padding: 0 0.75rem;
      padding-bottom: 1rem;
    }
    .highlight_color {
      color: #ea4237;
      font-weight: 500;
    }
  }
  .invitee_form {
    max-width: 700px;
    width: 100%;
    @media (max-width: 600px) {
      padding: 0 0.75rem;
    }
    .flex_box {
      display: flex;
      align-items: center;
      gap: 1.2rem;
      padding-bottom: 1rem;
      @media (max-width: 600px) {
        flex-wrap: wrap;
        &:first-child {
          flex-wrap: nowrap;
          .ant-form-item {
            flex: 1;
          }
        }
      }

      .ant-form-item {
        flex: 1 0 48%;
      }
    }
    .ant-divider-horizontal {
      margin: 0.75rem 0 1.5rem 0 !important;
      border-block-start: 1px solid $light_Black !important;

      @media (max-width: 600px) {
        margin: 0.75rem 0 1rem 0 !important;
      }
    }
    .soft_green {
      color: #b2eab6 !important;
      font-weight: 400;
      font-size: 24px;
      line-height: 34px;
      padding-bottom: 1rem;

      @media (max-width: 600px) {
        padding: 0 0 1rem 0 !important;
      }
    }

    .flex_button {
      text-align: center;
      padding-top: 0.75rem;
      .ant-btn-primary {
        width: 45%;
        @media (max-width: 600px) {
          width: 100%;
        }
      }
    }
  }
  .rg-signup {
    font-size: 14px;
    color: $rg-text-color;
    font-weight: 400;
    text-align: center;
    line-height: 1;
    padding: $size 0;
    text-transform: uppercase;
  }

  .terms {
    color: $Sand;
    font-size: 14px;
    margin-top: 40px;
    text-align: center;
    line-height: 2;
    padding-bottom: 1rem;
    a {
      color: $Sand !important;
    }
  }

  .anticon {
    svg {
      fill: $Mindaro_Yellow-Green;
    }
  }
}

.invitee_signup {
  .header_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .logo_asset {
      margin: 1rem 0 !important;
    }
    h2 {
      margin-bottom: 0.75rem;
    }
  }
}

.loader_center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100dvh;
}

.ant-input-suffix {
  color: #fff;
}

.ant-picker-suffix,
.ant-select-selection-item-remove {
  color: $Pure_White !important;
}

input {
  accent-color: $Pure_White;
}

.ant-input-affix-wrapper,
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:focus {
  background-color: $light_Black !important;
}

/* Autofill background color */
.ant-input:-webkit-autofill {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0px 1000px $light_Black inset !important;
  -webkit-text-fill-color: $Pure_White !important;
}

.ant-input:-webkit-autofill:hover,
.textbant-inputox:-webkit-autofill:focus,
.ant-input:-webkit-autofill:active {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0px 1000px $light_Black inset !important;
  -webkit-text-fill-color: $Pure_White !important;
}

.ant-input:-moz-autofill {
  background-color: transparent !important;
  -moz-box-shadow: 0 0 0px 1000px $light_Black inset !important;
  -moz-text-fill-color: $Pure_White !important;
}

.ant-input:-moz-autofill:hover,
.ant-input:-moz-autofill:focus,
.ant-input:-moz-autofill:active {
  background-color: transparent !important;
  -moz-box-shadow: 0 0 0px 1000px $light_Black inset !important;
  -moz-text-fill-color: $Pure_White !important;
}

.admin_invite {
  .invitee_form {
    .flex_box {
      display: none;
    }
    .flex_box:nth-child(2) {
      display: grid !important;
      width: 50%;
      gap: 1rem;
      margin: auto;
    }
    .flex_button {
      .ant-btn-primary {
        width: 50%;
      }
    }
  }
  .ant-divider-horizontal {
    display: none;
  }
  .soft_green {
    display: none;
  }
}
