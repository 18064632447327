.notifications_page {
  .filter-wrap {
    background-color: $Cool_Black;
    padding: 1rem;
    color: #fff !important;
    position: sticky;
    top: 65.5px;
    z-index: 10;

    h1 {
      color: #fff !important;
    }

    p {
      font-size: 20px;
    }

    .filter_input_field {
      padding-top: 0.75rem;
      .ant-select-selector {
        background-color: transparent;
      }
      .ant-select-arrow {
        color: #ffffff !important;
      }
      .ant-select-selection-placeholder {
        color: #ffffff !important;
      }
    }
  }

  @media (max-width: 567px) {
    .list-item-selected.noti_row {
      .noti_filter {
        max-width: 534px;
        width: 100%;
        @media (max-width: 769px) {
          max-width: 40%;
        }
        @media (max-width: 567px) {
          max-width: 100% !important;
          display: none;
        }
      }
      @media (max-width: 567px) {
        .noti_details {
          max-width: 100% !important;
          display: block;
        }
      }
    }
    .noti_row {
      .noti_filter {
        max-width: 534px;
        width: 100%;
        @media (max-width: 769px) {
          max-width: 40%;
        }
        @media (max-width: 567px) {
          max-width: 100% !important;
          display: block;
        }
      }
      @media (max-width: 567px) {
        .noti_details {
          max-width: 100%;
          display: none;
        }
      }
    }
  }

  .noti_row {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    // gap: 1rem;

    .noti_filter {
      max-width: 534px;
      width: 100%;
      @media (max-width: 769px) {
        max-width: 40%;
      }
      // @media (max-width:567px) {
      //    max-width: 100%;
      // }
    }

    .list_notifications {
      max-height: 65vh;
      overflow: auto;
      .no_text {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 50vh;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        h3 {
          font-size: 2.5rem !important;
          color: $Pure_White;
        }
      }

      .ant-card-body {
        padding: 0;
      }
      .ant-card {
        border: none !important;
      }
      .list_row {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 1rem;
        border-bottom: 1px solid $border-color;
        background: transparent !important;
        padding: 1rem;

        a {
          color: $Pure_White !important;
        }
        h2 {
          font-size: 16px;
          font-weight: 700;
          color: $Pure_White !important;
        }
        p {
          font-size: 14px;
          color: $Pure_White !important;
        }
        b {
          padding: 0 !important;
          font-size: 14px;
          font-weight: 700;
          color: $Pure_White !important;
        }
      }
    }

    .noti_details {
      flex: 1;
      background-color: #fff;
      padding: 1.5rem;
      border-left: 2px solid #d9d9d9;
      position: relative;

      h2 {
        font-size: 16px;
        font-weight: 700;
        line-height: 1.5;
      }
      p {
        font-size: 14px;
        line-height: 1.5;
      }
      b {
        padding: 0 !important;
        font-size: 14px;
        font-weight: 700;
      }

      .closed {
        position: absolute;
        top: 25px;
        right: 25px;
        font-size: 1rem;
        cursor: pointer;
        display: none;
        @media (max-width: 567px) {
          display: block;
        }
      }
    }
  }
}

.nav_list_notifications {
  min-height: 0;
  overflow: hidden;
  h3 {
    font-size: 0.75rem !important;
    color: $Pure_White;
  }
  .ant-card {
    border: none !important;
  }
  .notifi_lists {
    overflow: hidden;
    max-height: 50vh;
    @media (max-width: 600px) {
      max-height: 52vh;
    }
    .list_row {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 1rem;
      // border-top: 1px solid #d9d9d9;
      background: #f8fafb;
      padding: 0.5rem;

      a {
        color: #000 !important;
      }
      h2 {
        font-size: 16px;
        font-weight: 700;
      }
      p {
        font-size: 14px;
        @media (max-width: 600px) {
          max-width: 240px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      b {
        padding: 0 !important;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
  .ant-card-body {
    padding: 0;
  }
}

.list_row.active {
  border-bottom: 2px solid $border-color;
}

.list_row.unread {
  background: $light_Black !important;
}

.left_right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
}
