// variables

$base-color: #0f1217;
$border-dark: rgba($base-color, 0.88);
$base-bg-color: #003249;
$gap: 1rem;
$smallsize: 0.875rem;
$size: 1rem;
$rg-text-color: #0f1217;
$active-bg: radial-gradient(
  50% 50% at 50% 50%,
  rgba(249, 224, 133, 0.1) 0%,
  rgba(230, 186, 87, 0.1) 100%
);

$header-dropdown-bg: #283d52;
$radius: 10px;
$text-info: #595744;
$font-size: 16px;
// end variables

$spectral_font: 'Spectral', serif;
$work-sans_font: 'Work Sans', sans-serif;

$Cool_Black: #0f1217;
$light_Black: #30353b;
$navborder: #000000;
$border_color: #6d7278;
$Pure_White: #ffffff;
$Mindaro_Yellow-Green: #e7ff87;
$toggel_btn_color: #cbed42;
$Celadon_Green: #b2eab6;
$Soft_Emerald: #3e9c78;
$Periwinkle: #d2d5fc;
$Periwinkle_active: #a8aaca;
$Sand: #f0eee9;
$bm-color: #8b85d6;
$gary_hover_color: rgba(94, 96, 113, 0.15);
$Periwinkle_Dark: #5e6071;
$input_placeholder: #737c88;
$field_stroke: #454c55;
$filed_value_color: #a7a5a3;
$new_Sand: #e1decf;
$gray_color: #a1a1a1;
$basegray-300: #191b1e;
$Card_bg_color: linear-gradient(
  119.23deg,
  rgba(52, 126, 99, 0.15) -1.55%,
  rgba(171, 177, 207, 0.15) 101.44%
);

$work-sans-display-XS-display-xs-medium-font-family: 'Work Sans', Helvetica;
$work-sans-display-XS-display-xs-medium-font-weight: 500;
$work-sans-display-XS-display-xs-medium-font-size: 24px;
$work-sans-display-XS-display-xs-medium-letter-spacing: 0px;
$work-sans-display-XS-display-xs-medium-line-height: 32px;
$work-sans-display-XS-display-xs-medium-font-style: normal;
$work-sans-display-XS-display-xs-semibold-font-family: 'Work Sans', Helvetica;
$work-sans-display-XS-display-xs-semibold-font-weight: 600;
$work-sans-display-XS-display-xs-semibold-font-size: 24px;
$work-sans-display-XS-display-xs-semibold-letter-spacing: 0px;
$work-sans-display-XS-display-xs-semibold-line-height: 32px;
$work-sans-display-XS-display-xs-semibold-font-style: normal;
$work-sans-text-LG-text-lg-regular-font-family: 'Work Sans', Helvetica;
$work-sans-text-LG-text-lg-regular-font-weight: 400;
$work-sans-text-LG-text-lg-regular-font-size: 18px;
$work-sans-text-LG-text-lg-regular-letter-spacing: 0px;
$work-sans-text-LG-text-lg-regular-line-height: 28px;
$work-sans-text-LG-text-lg-regular-font-style: normal;
$work-sans-text-MD-text-md-medium-font-family: 'Work Sans', Helvetica;
$work-sans-text-MD-text-md-medium-font-weight: 500;
$work-sans-text-MD-text-md-medium-font-size: 16px;
$work-sans-text-MD-text-md-medium-letter-spacing: 0px;
$work-sans-text-MD-text-md-medium-line-height: 24px;
$work-sans-text-MD-text-md-medium-font-style: normal;
$work-sans-text-LG-text-lg-medium-font-family: 'Work Sans', Helvetica;
$work-sans-text-LG-text-lg-medium-font-weight: 500;
$work-sans-text-LG-text-lg-medium-font-size: 18px;
$work-sans-text-LG-text-lg-medium-letter-spacing: 0px;
$work-sans-text-LG-text-lg-medium-line-height: 28px;
$work-sans-text-LG-text-lg-medium-font-style: normal;
$shadow-portfolio-mockup-main-centre-md: 0px 75px 150px 0px
  rgba(52, 64, 84, 0.14);
$shadow-portfolio-mockup-main-centre-lg: 0px 100px 200px 0px
  rgba(52, 64, 84, 0.18);
$shadow-portfolio-mockup-overlay-right-lg: -100px 100px 150px 0px
  rgba(52, 64, 84, 0.12);
$shadow-portfolio-mockup-overlay-left-lg: 100px 100px 150px 0px
  rgba(52, 64, 84, 0.12);
$shadow-portfolio-mockup-grid-shadow-md: 32px 32px 64px 0px
  rgba(52, 64, 84, 0.08);

@import 'common/global';

@import 'layout/_forms';
@import 'layout/_grid';
@import 'layout/_navigations';
@import 'layout/_layout';
@import 'layout/_footer';

@import 'pages/_home';
@import 'pages/_login';
@import 'pages/_dashboard';
@import 'pages/_chat';
@import 'pages/_settings';
@import './pages/_image-gallery';
@import './pages/_cardlist';
@import './pages/_notifications';
@import './pages/_network';

// @import 'Utilities/_minxins';
// @import 'Utilities/_variables';

// @import 'themes/_dark';
// @import 'themes/_theme';

// @import 'fonts/fonts.scss';
