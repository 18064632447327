/*   overide styles */

// .ant-pagination {
//   display: none !important;
// }

.header-tracker {
  .ant-card-head-title {
    padding: 10px 0;
  }

  .ant-card-extra {
    padding: 10px 0;
  }

  .ant-card-body {
    padding: 8px !important;
  }
}

.task-chart {
  .ant-card {
    margin-bottom: 10px;
    height: 100%;
  }

  .gx-card-overview {
    height: 100%;
  }
}

.opp_unity_details .Secondary-textcolor {
  margin-top: 3.5rem !important;
}

.Secondary-textcolor {
  color: $Pure_White;

  &::first-letter {
    text-transform: capitalize;
  }
}

.gx-app-logo {
  img {
    max-width: 45px;
    display: inline-block;
    margin-left: 10px;
    object-fit: contain;
  }

  .logo-text {
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    margin-left: 5px;
    letter-spacing: 1px;
    display: inline-block;
  }
}

.task-title {
  font-weight: 700;
  font-size: 1rem;
}

.loader {
  text-align: center;
  /* background: rgba(0, 0, 0, 65%); */
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  display: none;
}

.on-highlighter {
  padding: 6px 0;
  /* border-bottom: 1px solid #e8e8e8; */
}

.on-highlighter {
  &:hover {
    background-color: #eaeaea;
  }
}

.on-highlighter:hover .EditOutlined {
  color: #5797fc;
}

.on-highlighter:hover .DeleteOutlined {
  color: #f44336;
}

.gx-site-logo img {
  max-width: 45px;
}

.card-timeline {
  &:hover::after {
    background-color: #eaeaea;
  }
}

.card-timeline {
  position: relative;
}

.card-timeline {
  &::after {
    content: '';
    position: absolute;
    /* margin: auto; */
    top: 9px;
    left: -5px;
    /* right: 0; */
    width: 10px;
    height: 10px;
    background: #fff;
    transform: rotate(135deg);
    border-right: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
  }
}

.time {
  background-color: #5797fc;
}

.ant-btn.active {
  color: #006eb8;
  border-color: #006eb8;
  background: #fff;
  z-index: 2;
}

.name-edit {
  height: 54px;
  width: 100%;
  font-weight: bold;
  font-size: 24px;
  text-transform: capitalize;
  color: #fff;
  border: 1px solid $border-color;

  @media (max-width: 600px) {
    height: 50px;
    font-size: 1rem;
    width: 92% !important;
  }
}

.edit_user {
  padding: 0rem 1rem 1rem 1rem;

  @media (max-width: 600px) {
    margin-bottom: 3rem;
  }
}

.ant-avatar-circle {
  position: relative;
}

.show_mobile {
  display: none;
  @media (max-width: 600px) {
    display: block;
  }
}

.wvp-p-flex-d {
  display: flex;
  flex-direction: column;

  .description_editor {
    padding-top: 1rem;
    position: relative;
    @media (max-width: 600px) {
      padding-top: 1rem;
    }
  }
  .counter {
    color: $gray_color;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-align: right;
    padding-top: 2px;
  }

  .err-message {
    position: absolute;
    bottom: 0;
    left: 0;
    p {
      color: #ff4d4f;
      font-size: 12px;
      line-height: 20px;
      font-weight: 500;
    }
  }
  @media (max-width: 600px) {
    margin-bottom: 100px;
    gap: 16px;
  }

  @media (max-width: 900px) {
    flex-direction: column;
  }

  .card {
    display: flex;
    justify-content: space-between;

    @media (max-width: 600px) {
      flex-direction: column;
    }

    .header-image {
      min-width: 518px;
      flex: 1 1;

      @media (max-width: 600px) {
        min-width: fit-content;
        max-width: 100%;
        width: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
        display: block;
        border-radius: 10px 0px 0px 10px;

        @media (max-width: 600px) {
          border-radius: 10px;
        }
      }
    }

    .card_left_body {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 1rem;
      gap: 1rem;

      @media (max-width: 600px) {
        padding: 1rem 0 0 0;
      }

      .Secondary-textcolor {
        padding: 0;
      }

      .cn_title {
        color: $Pure_White;
        font-size: 28px;
        font-weight: 400;
        line-height: 28px;
        padding: 0 12px;
      }

      .list_group {
        padding: 0 12px 0px 12px;

        @media (max-width: 600px) {
          padding: 0;
        }

        .text-group {
          padding: 6px 0;
          border-bottom: solid 1px $light_Black;
          font-size: 18px;
          color: $Pure_White;
          font-family: $work-sans_font;
          display: flex;
          align-items: center;
          justify-content: space-between;

          @media (max-width: 600px) {
            font-size: 14px;
          }

          > label {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            font-weight: 500 !important;
            line-height: 28px;
            max-width: 260px;

            @media (max-width: 600px) {
              max-width: 160px;
              line-height: 24px;
            }
          }

          & > span {
            padding-left: 10px;
            white-space: nowrap;
            overflow: hidden;
            font-weight: 400 !important;
            text-overflow: ellipsis;
            display: inline-block;
            line-height: 28px;
            max-width: 260px;

            @media (max-width: 600px) {
              max-width: 160px;
              line-height: 24px;
            }

            b {
              color: $Pure_White;
            }
          }
        }
      }

      .footer_social_icons {
        padding: 0 12px;
        margin-top: auto;

        .social_icons {
          margin-top: 10px;
        }

        .social_info {
          padding: 0;
        }
      }
    }
  }

  .footer_social_icons {
    padding-top: 1rem;
  }

  .deck-slideshow {
    height: 100%;
    width: 100%;

    .re-company-deck {
      @media (max-width: 600px) {
        padding-left: 0rem;
      }

      .side-view-comapny {
        display: block;
        padding: 0px;
        border-radius: 0px;
        background: unset;

        .profile_hide {
          // display: none;
        }
      }
    }

    .inv_slide_view {
      .list_group {
        .text-group {
          padding: 10px 0;
          border-bottom: solid 1px $light_Black;
          font-size: 18px;
          color: $Pure_White;
          font-family: $work-sans_font;
          display: flex;
          align-items: center;
          justify-content: space-between;

          @media (max-width: 600px) {
            font-size: 14px;
          }

          > label {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            font-weight: 500 !important;
            line-height: 28px;
            max-width: 260px;

            @media (max-width: 600px) {
              max-width: 160px;
              line-height: 24px;
            }
          }

          & > span {
            padding-left: 10px;
            white-space: nowrap;
            overflow: hidden;
            font-weight: 400 !important;
            text-overflow: ellipsis;
            display: inline-block;
            line-height: 28px;
            max-width: 260px;

            @media (max-width: 600px) {
              max-width: 160px;
              line-height: 24px;
            }
          }

          a {
            color: $Periwinkle_active;

            & > span {
              padding-left: 10px;
              white-space: nowrap;
              overflow: hidden;
              font-weight: 400 !important;
              text-overflow: ellipsis;
              display: inline-block;
              line-height: 28px;
              max-width: 260px;

              @media (max-width: 600px) {
                max-width: 160px;
                line-height: 24px;
              }
            }
          }

          b {
            color: $Pure_White;
          }
        }
      }
    }

    .tabs-bottom {
      height: 100%;
    }

    .ant-tabs-nav {
      padding-top: 6px;
      margin-bottom: 30px;

      @media (max-width: 600px) {
        margin-bottom: 1rem;
      }
    }

    video {
      max-width: 100%;
      max-height: 100%;
      display: block;
      object-fit: fill;
      margin-top: 1rem;
    }
  }

  .grid-3 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(287px, 1fr));
    gap: 1rem;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 600px) {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 1rem;
    }

    .label-group {
      justify-self: flex-start;
      display: flex;
      flex-direction: column;
      gap: 5px;
      font-size: 18px;

      @media (max-width: 600px) {
        font-size: 14px;
        gap: 0px;
      }

      label {
        color: $Pure_White;
        font-weight: 500;

        @media (max-width: 600px) {
          line-height: 24px;
        }
      }

      p {
        margin: 0 0 15px 0;
        color: $filed_value_color;
        font-weight: 400;
        max-width: 240px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: block;

        @media (max-width: 600px) {
          margin: 0;
          line-height: 24px;
        }

        a {
          color: $filed_value_color;
        }
      }
    }

    @media (max-width: 1200px) {
      .col-span-2 {
        grid-column: span 1 / span 1;
      }
    }
  }

  .grid-4 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 1rem;
    justify-content: space-between;

    @media (max-width: 600px) {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 1rem;
    }

    p {
      display: inline-block;
      max-width: 210px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      // text-transform: capitalize;
    }

    .label-group {
      justify-self: flex-start;
      display: flex;
      flex-direction: column;
      gap: 5px;

      p {
        margin: 0 0 15px 0;
      }
    }
  }

  .flex-warp {
    display: flex;
    flex-wrap: wrap;
    gap: 35px;
  }
}

.grid-2 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(430px, 1fr));
  justify-content: space-between;
  gap: 1rem;

  @media (max-width: 600px) {
    grid-template-columns: repeat(auto-fill, minmax(249px, 1fr));
  }

  .bg-primary {
    width: 100%;
  }

  @media (max-width: 576px) {
    padding: 0 !important;
  }
}

.pdf-canvas {
  background: #ccc;
}

.pr-team {
  margin-top: 1.2rem;
}

.sm_social {
  display: flex;
  gap: 1rem;

  @media (max-width: 600px) {
    justify-content: space-between;
  }

  @media (max-width: 600px) {
    .ant-form-item {
      flex: 1 1 auto;
    }

    .ant-form-item:nth-child(2) {
      flex: none;
    }
  }

  .bg-primary {
    width: 287px;
    max-width: 100%;
    height: 41px;

    @media (max-width: 600px) {
      width: 100% !important;
    }
  }
}

.btn_delete {
  .ant-btn {
    @media (max-width: 600px) {
      text-wrap: balance;
      height: 100%;
    }
  }
}

.social_editor_view {
  margin-top: 1rem;
}

//social_icons
.social_icons_view {
  gap: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  .social_info {
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 0.5rem;
    @media (max-width: 600px) {
      justify-content: space-between;
    }

    .social-icon {
      width: 2.5rem !important;
      height: 2.5rem !important;

      .social-svg-icon {
        fill: $Pure_White !important;
      }

      .social-svg-mask {
        fill: $light_Black !important;
      }
    }
  }

  .social_info {
    padding: 0 !important;
  }
}

.social_icons {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;

  @media (max-width: 600px) {
    margin-left: 0;
    flex-direction: column;
  }

  .ant-tag {
    background-color: $light_Black !important;
    color: $Pure_White;
    border: none;
    margin-right: 0;
    padding: 0 !important;

    @media (max-width: 600px) {
      width: 100%;
    }

    .ant-typography {
      color: $Pure_White;
      margin: 0;
      max-width: 160px;
      flex: 1;
    }
  }

  .social_info {
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 0.75rem;

    @media (max-width: 600px) {
      justify-content: space-between;
    }

    .social-icon {
      width: 43px !important;
      height: 43px !important;

      .social-svg-icon {
        fill: $Pure_White !important;
      }

      .social-svg-mask {
        fill: #5e60714d !important;
      }
    }
  }

  .trash_icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #5e60714d;

    &:hover {
      background: $gary_hover_color;
    }
  }
}
.social_info {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  gap: 0.5rem;

  @media (max-width: 600px) {
    justify-content: center;
  }

  .social-icon {
    width: 32px !important;
    height: 32px !important;

    .social-svg-icon {
      fill: $Pure_White !important;
    }

    .social-svg-mask {
      fill: $light_Black !important;
    }
  }
}
.actions_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;
  gap: 1rem;

  .switch_btn {
    // padding-top: 2rem;

    @media (max-width: 600px) {
      padding-bottom: 1rem;
      align-self: flex-start;
    }
  }

  .del_account {
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    color: #f04438;
    cursor: pointer;

    @media screen and (max-width: 600px) {
      text-align: left;
      margin-top: 32px;
    }
  }

  @media (max-width: 600px) {
    width: 100%;
    flex-direction: column;
    padding: 0;
    .ant-form-item {
      width: 100%;
    }
  }
  .ant-btn-default {
    @media (max-width: 600px) {
      display: none;
    }
  }
  .ant-btn {
    @media (max-width: 600px) {
      margin-bottom: 1rem;
    }
  }
}

.trash_icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #5e60714d;

  &:hover {
    background: $gary_hover_color;
  }
}

.team_view {
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
  margin-top: 1.5rem;

  @media (max-width: 600px) {
    justify-content: center;
    padding: 0 1rem;
  }

  a {
    text-decoration: none;
  }

  .team_card {
    max-width: 405px;
    width: 100%;
    min-height: 250px;
    position: relative;
    text-align: center;

    .team_profile_img {
      width: 76px;
      height: 76px;
      border-radius: 50%;
      margin: auto;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .teammember-list {
      h2 {
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        max-width: -webkit-fill-available !important;
      }

      h3 {
        color: $gray_color;
        max-width: 180px;
        margin: auto;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        word-wrap: break-word;
        text-wrap: balance;
      }
    }

    .fixed_top {
      position: absolute;
      top: -25px;
      right: -20px;
    }
  }
}

.list_group {
  .text-group {
    padding: 10px 0;
    border-bottom: solid 1px $light_Black;
    font-size: 18px;
    color: $Pure_White;
    font-family: $work-sans_font;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 600px) {
      font-size: 14px;
    }

    & > label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      font-weight: 500 !important;
      line-height: 28px;
      max-width: 260px;

      @media (max-width: 600px) {
        max-width: 160px;
        line-height: 24px;
      }
    }

    & > span {
      padding-left: 10px;
      white-space: nowrap;
      overflow: hidden;
      font-weight: 400 !important;
      text-overflow: ellipsis;
      display: inline-block;
      line-height: 28px;
      max-width: 260px;

      @media (max-width: 600px) {
        max-width: 160px;
        line-height: 24px;
      }
    }

    a {
      color: $Periwinkle_active;

      & > span {
        padding-left: 10px;
        white-space: nowrap;
        overflow: hidden;
        font-weight: 400 !important;
        text-overflow: ellipsis;
        display: inline-block;
        line-height: 28px;
        max-width: 260px;

        @media (max-width: 600px) {
          max-width: 160px;
          line-height: 24px;
        }
      }
    }

    b {
      color: $Pure_White;
    }
  }
}

.ant-btn-default:disabled {
  color: $light_Black !important;
}

//  new design css...

.main-container {
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 32px;
  position: relative;
  margin: 0 auto;
  border-radius: 4px;
  margin-top: 32px;

  .grid_wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
    justify-content: space-between;
    gap: 1rem;
    width: 100%;

    @media (max-width: 600px) {
      grid-template-columns: repeat(auto-fill, minmax(249px, 1fr));
      gap: 1.5rem;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-wrap: nowrap;
      gap: 8px;
      position: relative;
      max-width: 400px;
      width: 100%;
    }
  }

  .text {
    position: relative;
    color: $Pure_White;
    font-family: $work-sans_font;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;

    @media (max-width: 600px) {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  .group {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: wrap;
    flex-shrink: 0;
    gap: 8px;
    position: relative;
    min-width: 0;
  }

  .text-2 {
    position: relative;
    color: $Pure_White;
    font-family: $work-sans_font;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;

    @media (max-width: 600px) {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }

  .box {
    gap: 10px;
    position: relative;
    padding: 4px 8px 4px 8px;
    background: $light_Black;
    border-radius: 4px;
  }

  .box-9 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 8px;
    position: relative;
    width: 100%;
  }

  .container {
    display: flex;
    width: 100%;
    gap: 2rem;
    align-self: stretch;

    @media screen and (max-width: 600px) {
      flex-direction: column;
    }

    h3 {
      margin-bottom: 15px;
      line-height: 1.75rem;
      font-weight: 600;
      font-size: 1.125rem;
      color: $Pure_White;

      @media (max-width: 600px) {
        font-size: 1rem;
        line-height: 1.5rem;
        margin-bottom: 8px;
      }
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        margin-bottom: 8px;
        font-size: 1rem;
        color: $Pure_White;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 0.5rem;
        &:last-child {
          margin-bottom: 0px;
        }
        @media (max-width: 600px) {
          font-size: 0.875rem;
          line-height: 1.25rem;
          justify-content: flex-start;
          align-items: flex-start;
        }

        strong {
          font-weight: 500;
          color: $gray_color;
          @media (max-width: 600px) {
            width: 100%;
          }
        }

        span {
          text-overflow: ellipsis;
          overflow: hidden;
          font-weight: 400;
          line-height: 1.5rem;
        }
      }
    }
    .impact-areas {
      max-width: 52.5rem;
    }
    // .impact-areas,
    // .investment-successes {
    //   // width: 50%;
    // }

    .investment-successes {
      display: flex;
      gap: 8px;
      flex-direction: column;
      flex: 1;
      h3 {
        margin-bottom: 0;
      }
      @media (max-width: 600px) {
        flex-direction: column;
      }
      ul {
        li {
          align-items: center;
          strong {
            width: auto;
          }
          span {
            word-wrap: break-word;
            text-wrap-style: balance;
            word-break: break-word;
          }
        }
      }
    }
  }

  .firm-info {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    @media screen and (max-width: 600px) {
      flex-direction: column;
      gap: 1rem;
      margin-bottom: 180px;
    }

    @media screen and (max-width: 800px) {
    }

    .logo-section {
      width: 20%;

      img {
        max-width: 100%;
        height: auto;
      }

      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }

    .details-section {
      width: 65%;
      flex: 1;

      @media screen and (max-width: 600px) {
        width: 100%;
      }

      .firm_title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        padding-bottom: 8px;

        @media screen and (max-width: 600px) {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
        }

        h3 {
          font-size: 1.5rem;
          font-weight: 600;
          color: #fff;
          line-height: 2rem;

          @media (max-width: 600px) {
            font-size: 1.25rem;
            line-height: 1.25rem;
          }
        }

        .website-section {
          a {
            color: $Mindaro_Yellow-Green;
            text-decoration: none;
            font-size: 1rem;

            @media (max-width: 600px) {
              font-size: 0.875rem;
              font-weight: 500;
            }
          }
        }
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          font-size: 1rem;
          color: $Pure_White;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;

          @media (max-width: 600px) {
            font-size: 0.875rem;
            line-height: 1.25rem;
          }

          strong {
            font-weight: 500;
            color: $gray_color;
          }

          span {
            text-overflow: ellipsis;
            width: 240px;
            overflow: hidden;
            font-weight: 400;
            line-height: 1.5rem;
          }
        }
      }

      .description {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
        // display: -webkit-box;
        // -webkit-line-clamp: 3;
        // line-clamp: 3;
        // -webkit-box-orient: vertical;
        color: $Pure_White;

        .ql-editor {
          padding: 0;
          color: $Pure_White;
        }
      }
    }
  }
}

.ql-editor {
  padding: 0;
  color: $Pure_White;
}
