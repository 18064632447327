/*Header Styles*/
.header-dropdown {
  // float: right;
  background: $header-dropdown-bg;
  padding: 0.5rem 1rem;
  color: #fff;
}

@media (max-width: 1200px) {
  .layout-content {
    max-width: 1440px;
    margin: auto 0rem;
    min-height: 90.6dvh;
  }
}

@media (min-width: 1200px) {
  .layout-content {
    max-width: 1440px;
    margin: 0rem auto;
    width: 100%;
    min-height: 90.6dvh;
    // background-color: $light_Black;
  }

  .menu {
    display: none;
  }
}

.mult_selected {
}

.ant-select-dropdown {
  background: $Cool_Black;
  border: 1px solid $light_Black;
}

// .ant-select-item-option{
//   &:hover{
//     background: $light_Black;
//     color: $Pure_White;
//   }
// }
.ant-select-item-option-selected {
  background: $light_Black !important;
  color: $Pure_White !important;

  .ant-select-item-option-state {
    color: $Periwinkle !important;
  }
}

.ant-select-item-option {
  color: $Pure_White !important;
}

.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 1px solid transparent !important;
}

.tag_lists {
  @media (max-width: 600px) {
    flex-direction: column;
    width: 100%;
  }
  .ant-space-item {
    @media (max-width: 600px) {
      width: 100%;
    }
  }
  .ant-tag {
    background-color: #5e60714d !important;
    color: $Pure_White;
    border-radius: 6px;
    padding: 6px 8px;
    border: 1px solid $field_stroke;
    @media (max-width: 600px) {
      width: 100%;
    }

    .ant-typography {
      color: $Pure_White;
    }

    .tag_text {
      font-size: 16px !important;
      font-weight: 400;
      line-height: 28px;
    }
  }
}

/*Error Message*/
.ant-form-item-explain-error {
  text-align: left;
  font-size: 0.7rem;
  line-height: 1rem;
}

/*Ant Styles overwrites */
.ant-card-head {
  padding: 10px 15px !important;
  min-height: 1px !important;
  border-bottom: none !important;
}

/*Animations*/
@keyframes btn-animate {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.cr-layout {
  .scroll-h-img {
    overflow: auto;
    white-space: nowrap;
    padding: 5px 70px 5px 20px;
    background: transparent;
    height: 100%;
    border-radius: 15px;
    margin-bottom: 1rem;

    .div-img {
      max-width: 120px;
      height: 60px;
      display: inline-block;
      margin: 0 0.5rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

.img-add-space {
  height: 100%;
  display: flex;
  cursor: pointer;

  .upload-container {
    display: flex;
    align-items: center;
    border: 2px dashed $field_stroke;
    padding: 16px;
    border-radius: 6px 0 0px 6px;
    width: 100%;
    height: 100%;

    .upload-box {
      flex: 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      color: $Pure_White;
      cursor: pointer;
      text-align: center;
    }

    .upload-icon {
      font-size: 24px;
      margin-right: 1rem;
    }

    .file-size-info {
      color: $filed_value_color;
      font-size: 12px;
      line-height: 18px;
    }

    input {
      max-width: 106px;
      height: 43px;
      border-radius: 5px;
      position: relative;
      border-style: dashed;
      cursor: pointer;
      color: transparent;
      background: transparent;
      padding: 0;

      &:hover {
        border-inline-end-width: 1px;
      }

      &::-webkit-file-upload-button {
        visibility: hidden;
        opacity: 0;
      }

      &::after {
        content: 'Select file';
        background-color: $Periwinkle;
        color: $Cool_Black;
        border: none;
        border-radius: 5px;
        text-align: center;
        cursor: pointer;
        font-size: 16px;
        height: 100%;
        max-width: 100%;
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        line-height: 43px;
        transition: background-color 0.3s;
      }
    }
  }
}

.oppo-view {
  .row-flex {
    display: flex;
    flex-direction: row;
    // align-items: center;
    justify-content: center;
    max-width: 1440px;
    width: 100%;

    .Tabs-slideshow {
      padding: 0 1rem;
      height: 100%;
      width: 100%;
      background-color: #fff;
    }
  }

  .sponsor-info {
    .text-group {
      display: flex;
    }
  }

  .opportunity-details {
    display: flex;
    align-items: center;
    padding-top: 1.5rem;
    flex-wrap: wrap;

    .label-group {
      margin-bottom: 15px;
      flex: 1;
      color: $Pure_White;

      @media (max-width: 600px) {
        flex: 1 1 100%;
      }

      label {
        font-weight: 600;
        color: $Pure_White;
      }

      a {
        color: $Celadon_Green;
      }

      div {
        font-weight: 400;
        text-transform: capitalize;
        font-size: 16px !important;
        color: $Pure_White;
      }
    }
  }

  .deals-information {
    display: flex;
    align-items: center;
    gap: 2rem;
    padding-top: 1.5rem;
    flex-wrap: wrap;

    .label-group {
      margin-bottom: 15px;
      width: 45%;

      label {
        color: $Pure_White;
        font-weight: 600;
      }

      a {
        color: $Celadon_Green;
      }

      div {
        font-weight: 400;
        text-transform: capitalize;
      }
    }
  }
}

.ant-modal-content {
  background-color: $Cool_Black !important;
  border: 1px solid $border-color !important;

  .ant-modal-close {
    color: $Pure_White;

    &:hover {
      color: $Periwinkle;
    }
  }

  .ant-modal-header {
    background-color: transparent !important;

    .ant-modal-title {
      color: $Pure_White !important;
      font-size: 1.4rem !important;

      h1 {
        color: $Pure_White !important;
        font-size: 20px !important;
      }
    }
  }

  .ant-modal-body {
    p,
    h3,
    h4 {
      color: $Pure_White;
    }

    a {
      color: $Mindaro_Yellow-Green;
    }
  }

  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    align-items: center;
    @media (max-width: 600px) {
      flex-direction: column-reverse;
      gap: 1rem;
      align-items: end;
    }
    .ant-btn-default {
      color: $Pure_White;
    }
  }
}

.table-container {
  border: 1px solid $light_Black;
  border-radius: 0.5rem;
  background-color: $Cool_Black;
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    min-width: 600px;
  }

  table thead {
    background: #1e2225;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  thead tr {
    border-bottom: 1px solid $light_Black;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  thead tr th:first-child {
    border-top-left-radius: 0.5rem;
  }

  thead tr th:last-child {
    border-top-right-radius: 0.5rem;
  }

  td {
    padding: 16px 24px;
    text-align: left;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: $Pure_White;
  }

  th {
    color: #fff;
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    color: $Pure_White;
    padding: 16px 24px;
  }

  tbody tr {
    border-bottom: 1px solid $light_Black;
  }

  .message-btn {
    border: 1px solid $Pure_White;
    font-size: 18px;
    padding: 0 2rem;
    margin-right: 0.75rem;
    font-weight: 400;
    color: #fff;
    @media (max-width: 600px) {
      font-size: 14px !important;
      padding: 0 0.6rem;
      max-height: 32px;
    }
    &:hover {
      background: $Mindaro_Yellow-Green;
      border: 1px solid $Mindaro_Yellow-Green;
    }
  }
  .copy_btn {
    margin-left: 2rem;
  }
  .profile-btn {
    border: 1px solid $Pure_White;
    font-size: 18px;
    font-weight: 400;
    padding: 0 2rem;
    margin-right: 0.75rem;
    color: #fff;

    &:hover {
      background: $Mindaro_Yellow-Green;
      border: 1px solid $Mindaro_Yellow-Green;
    }
  }
}

@media screen and (max-width: 600px) {
  .table-container {
    margin-bottom: 5rem !important;
    max-height: 70dvh;
  }

  th,
  td {
    padding: 10px !important;
    font-size: 14px !important;
  }

  .copy_btn {
    padding: 6px 10px !important;
    font-size: 12px !important;
    width: auto !important;
    height: 32px;
  }
}

.ant-select {
  height: 44px !important;
  border-radius: 4px;
  border: none !important;

  .ant-select-selector {
    background: $light_Black !important;
    border: none !important;
    color: $Pure_White;
    border-radius: 4px !important;
  }

  .ant-select-arrow {
    color: $Pure_White !important;
  }

  .ant-select-selection-item-remove {
    color: $Pure_White !important;
  }
}

.ant-input-affix-wrapper {
  background: $light_Black !important;
  color: $Pure_White !important;
  border: none !important;
  width: 100%;
  padding: 10px 14px 10px 14px;
  border-radius: 4px;

  &::placeholder {
    color: $input_placeholder;
  }

  .ant-input {
    border: 0 !important;
  }
}
.ant-select-selection-placeholder {
  color: $input_placeholder !important;
}
.ant-input,
.ant-picker,
.ant-input-number-affix-wrapper {
  background: $light_Black !important;
  color: $Pure_White !important;
  border: none !important;
  width: 100%;
  padding: 10px 14px 10px 14px;
  border-radius: 4px;

  &::placeholder {
    color: $input_placeholder;
  }
}

.ant-input-number-affix-wrapper {
  padding: 0 0px 0 14px !important;

  .ant-input-number {
    border: 0 !important;
  }
}

.ant-input-number {
  background: $light_Black !important;
  color: $Pure_White !important;
  border: none !important;
  width: 100%;
  padding: 6px 14px 6px 14px;
  border-radius: 4px;

  &::placeholder {
    color: $input_placeholder;
  }
}

.ant_input {
  background: $light_Black !important;
  color: $Pure_White !important;
  border: none !important;
  max-width: 100%;
  width: 300px;
  padding: 10px 14px 10px 14px;

  @media (max-width: 600px) {
    width: 100% !important;
  }

  &::placeholder {
    color: $input_placeholder;
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba(238, 255, 5, 0.06);
    outline: 0;
  }
}

.ant-input-number-input {
  color: $Pure_White !important;
}

.ant-input-number-handler-wrap {
  color: $Pure_White;
}

.ant-popover {
  // --antd-arrow-background-color: #0f1217;
  .ant-popover-arrow {
  }

  .ant-popover-inner {
    background: $Cool_Black;
    border: 1px solid $border-color;
    @media (max-width: 600px) {
      max-width: 600px;
      max-height: 400px;
      overflow: auto;
      margin: 0 12px;
    }
    .ant-popover-title {
      color: $Pure_White;
    }
  }
}

input[type='file'] {
  background-color: $light_Black;
  color: $Pure_White;
}

.ant-card {
  background: $Cool_Black;
  color: $Pure_White;
  border: 1px solid $border-color;

  .ant-avatar-circle {
    background: $light_Black;
  }

  .ant-card-meta-detail {
    .ant-card-meta-title,
    .ant-card-meta-description {
      color: $Pure_White;
      word-break: break-word;
    }
  }
}
