.navbar {
  position: sticky;
  top: 0;
  z-index: 10;
  background: $Cool_Black;

  &-header {
    border-bottom: 1px solid #191b1e;
    width: 100%;
    z-index: 10;
  }

  &-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.875rem 5rem;

    @media (max-width: 600px) {
      padding: 13px 16px;
    }
    @media (max-width: 1280px) {
      padding: 16px 16px;
    }
  }

  .mobile_header_title {
    h1 {
      display: none;

      @media (max-width: 567px) {
        display: block !important;
        font-size: 22px;
        color: $Pure_White;
        text-decoration: 1px solid $Mindaro_Yellow-Green;
        font-family: $spectral_font;
      }
    }
  }

  &-logo {
    display: block;
    height: 36px;
    width: 85px;
    @media (max-width: 600px) {
      display: none;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  &-menu {
    list-style: none;
    margin-left: auto;
    padding: 0;
    display: flex;

    @media (max-width: 600px) {
      display: none;
    }

    & .navbar-item {
      margin-right: 25px;
      height: 52px;
      position: relative;
      display: flex;
      align-items: center;
      .anticon {
        cursor: pointer;
      }

      & .badge_count {
        position: absolute;
        top: 6%;
        right: -15px;
        min-width: 20px;
        height: 20px;
        background-color: #ffffff;
        color: #0f1217;
        border-radius: 50%;
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
        white-space: nowrap;
        text-align: center;
        box-shadow: 0 0 0 1px #ffffff;
      }

      & .navbar-link {
        color: $Pure_White;
        text-decoration: none;
        font-weight: 300;
        font-family: $spectral_font;
        font-size: 1.5rem;
        line-height: 36px;
        letter-spacing: 0px;
        transition: color 0.3s ease;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0px;
          width: 0%;
          height: 1px;
          background-color: $Mindaro_Yellow-Green;
          transition: width 0.5s;
        }

        &:hover::after {
          width: 100%;
        }
      }

      .active::after {
        width: 100%;
      }

      & .navbar-img {
        background-color: $Soft_Emerald;
        border: 2px solid $Sand;
        height: 42px !important;
        width: 42px !important;
      }
    }
  }

  &-profile {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
    column-gap: 1rem;

    @media (max-width: 600px) {
      margin-left: auto;
    }

    & .navbar-item {
      display: flex;
      align-items: center;
      .anticon {
        cursor: pointer;
      }
      & .bagre-bell {
        width: 52px;
        height: 52px;
        border-radius: 30px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;

        &:hover {
          background-color: $gary_hover_color;
        }
        & .badge_count {
          position: absolute;
          top: -10px;
          right: -10px;
          min-width: 20px;
          height: 20px;
          background-color: #ffffff;
          color: #0f1217;
          border-radius: 50%;
          font-weight: 700;
          font-size: 12px;
          line-height: 20px;
          white-space: nowrap;
          text-align: center;
          box-shadow: 0 0 0 1px #ffffff;
        }
      }

      & .navbar-img {
        background-color: $Soft_Emerald;
        border: 2px solid $Sand;
        height: 42px !important;
        width: 42px !important;
      }
    }
  }
}

.admin-view {
  .navbar {
    &-menu {
      & .navbar-item {
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.profile-card {
  background: $Cool_Black;
  // padding: 1rem;
  border-radius: 4px;
  width: 224px;
  box-shadow: 0 0 10px #5151511a;
  border: 1px solid $light_Black;
  @media (max-width: 600px) {
    margin: 0 14px;
  }
  .profile-header {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 8px;
    cursor: pointer;

    .profile-picture {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }

    .profile-info h2 {
      font-size: 16px;
      line-height: 18px;
      font-weight: 400;
      color: $Pure_White;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 110px;
    }

    .profile-info p {
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      color: $Periwinkle_Dark;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 150px;
    }

    &:hover {
      background-color: $gary_hover_color;
    }
  }
}

hr {
  border: 0;
  border-top: 1px solid #515151;
}

.profile-menu {
  text-align: left;

  .menu-item {
    display: flex;
    align-items: center;
    padding: 8px 8px;
    cursor: pointer;

    a {
      color: $Pure_White;
    }

    span {
      font-size: 16px;
      color: $Pure_White;
    }

    &:hover {
      background-color: $gary_hover_color;
    }

    .icon {
      margin-right: 10px;
      color: $Periwinkle;
    }
  }
}

.ddl-list {
  background-color: $Pure_White;
  list-style: none;
  border-radius: 6px;
  width: 240px;

  .ddl-item {
    border-bottom: 1px solid $Sand;
    padding: 0.5rem 1rem;

    &:last-of-type {
      border: 0;
    }

    a {
      color: rgba(15, 18, 23, 0.88);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 0.75rem;

      .ddl-text {
        font-size: 14px;
        text-transform: capitalize;
        color: rgba(15, 18, 23, 0.88);
      }
    }
  }
}

// moblie menu state
.m-menu {
  display: none;
}

@media only screen and (max-width: 600px) {
  .m-menu {
    display: block;

    .m-menu_item {
      padding: 16px 20px 16px 20px;
      width: 100%;
      position: fixed;
      z-index: 2;
      bottom: 0px;
      right: 0px;
      left: 0px;
      border-top: 1px solid $light_Black;
      background-color: $Cool_Black;
      display: flex;

      li {
        list-style: none;
        text-align: center;
        cursor: pointer;
        display: flex;
        gap: 4px;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        text-align: center;
        -webkit-box-flex: 1;
        flex-grow: 1;
        cursor: pointer;
        position: relative;
        button {
          flex-direction: column;
          display: flex;
          align-items: center;
          gap: 2px;
        }

        & .badge_count {
          position: absolute;
          top: -4px;
          left: 50px;
          z-index: 10;
          min-width: 16px;
          font-size: 10px;
          height: 16px;
          font-weight: 700;
          background-color: #ffffff;
          color: #0f1217;
          border-radius: 50%;
          line-height: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0 0 0 1px #ffffff;
        }
      }

      li.active {
        .meun_icon {
          svg {
            path {
              fill: $Mindaro_Yellow-Green;
            }
          }
        }

        .menu_title {
          color: $Pure_White;
          font-family: $work-sans_font;
        }
      }

      .meun_icon {
        object-fit: contain;
        max-height: 24px;
        min-height: 24px;
        max-width: 24px;
        min-width: 24px;
        position: relative;
        z-index: 1;
      }

      .menu_title {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #595959;
        font-family: $work-sans_font;
      }
    }
  }
}

// moblie meun end

// notifications popup state
.notifications-popup {
  background-color: $Cool_Black;
  color: $Pure_White;
  width: 410px;
  border-radius: 10px;

  @media (max-width: 600px) {
    width: 100%;
  }

  a {
    text-decoration: none;
  }

  .notification {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0.5rem;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
      background-color: #272a32;
    }

    h3 {
      color: $Pure_White;
      text-align: center;
      font-size: 1rem;
    }

    .content {
      .icon {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-bottom: 0.5rem;

        .bell-icon {
          font-size: 1.5em;
          color: $Mindaro_Yellow-Green;
          height: 35px;
          width: 35px;
          border-radius: 50%;
          background-color: rgba(248, 255, 218, 0.1);
          display: flex;
          align-items: center;
          justify-content: center;
        }

        strong {
          color: $Mindaro_Yellow-Green;
          display: block;
          font-weight: 400;
          font-size: 1.125rem;
          display: inline-block;
        }
      }

      p {
        margin: 0;
        font-size: 1rem;
        line-height: 23px;
      }

      small {
        font-size: 0.813rem;
        color: $Periwinkle;
        font-weight: 400;
      }
    }
  }

  .see-all {
    width: 100%;
    padding: 10px;
    background-color: $Cool_Black;
    color: $Sand;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9em;

    &:hover {
      background-color: lighten($Sand, 10%);
    }
  }
}

.right-side-popup {
  position: fixed;
  right: 0;
  top: 0;
  width: 450px;
  height: 100%;
  background-color: $Cool_Black;
  color: $Pure_White;
  border-radius: 10px 0 0 10px;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.5);
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 10;
  transition: right 1s;

  @media (max-width: 600px) {
    width: 100%;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    margin-bottom: 1rem;
    border-bottom: 1px solid $border-color;

    h3 {
      font-size: 1.2rem;
      color: $Periwinkle;
    }

    .close-btn {
      background: none;
      border: none;
      color: $Pure_White;
      font-size: 1.5rem;
      cursor: pointer;
    }
  }

  .notifications-content {
    overflow-y: auto;
    flex-grow: 1;
  }
}
