.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $Cool_Black;
  color: $Pure_White;
  margin: auto;
  height: 190px;
  padding: 50px 75px 50px 75px;
  border-top: 1px solid $light_Black;
  margin-top: 1.5rem;

  @media (max-width: 600px) {
    display: none;
  }
  .footer-left {
    display: flex;
    flex-direction: column;

    .footer-logo {
      width: 176px;
      height: 35px;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    .footer-links {
      display: flex;
      gap: 20px;
      padding-top: 2rem;

      a {
        color: $Pure_White;
        text-decoration: none;
        font-size: 14px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .footer-right {
    display: flex;
    align-items: center;

    .footer-menu {
      display: flex;
      gap: 10px;
      flex-direction: column;

      a {
        color: $Pure_White;
        text-decoration: none;
        font-size: 14px;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .footer-contact {
      display: flex;
      gap: 16px;
      flex-direction: column;
      margin-left: 4rem;

      a {
        color: $Pure_White;
        text-decoration: none;
        font-size: 14px;

        &:hover {
          text-decoration: underline;
        }
      }

      .linkedin {
        margin-right: 1em;
      }

      .x {
      }
    }
  }
}
