.baselayout {
  .portallayout {
    margin: 0 auto;
    max-width: 1440px;
    min-height: 100dvh;
    width: 100%;

    .body_layout,
    .Opportunitie_layout {
      max-width: 1280px;
      width: 100%;
      margin: auto;
      min-height: 100dvh;
      @media (max-width: 1280px) {
        padding: 0 16px;
      }
    }
    .chat_row {
      max-width: 1280px;
      width: 100%;
      margin: auto;
      margin-top: 40px;
      min-height: 80dvh;

      @media (max-width: 600px) {
        margin-top: 0;
      }
    }
  }
}
