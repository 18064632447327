.network-page {
  .network_header {
    padding: 0.75rem 0.75rem !important;
    margin-bottom: 14px;
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 65.5px;
    z-index: 10;
    @media (max-width: 567px) {
      flex-direction: column;
      justify-content: center;
      gap: 12px;
      margin-bottom: 0;
    }
    h1 {
      color: #ffffff !important;
      @media (max-width: 567px) {
        display: none;
      }
    }
    p {
      color: #ffffff !important;
      font-size: $size;
      font-weight: 400;
      text-align: center;
      margin-bottom: 1.5rem;
    }
  }
  .container_table {
    margin: 1.875rem 0.75rem;
    @media (max-width: 567px) {
      margin: 0rem;
    }
  }
  .net_router_header {
    display: flex;
    justify-content: right;
    align-items: center;
    flex-flow: row-reverse;
    gap: 1rem;
    .icon {
      // width: 120px;
      // height: 120px;
      background-color: #fff;
      border: 1px solid transparent;
    }
    .active {
      color: #ffffff;
      background-color: $base-bg-color;
    }
  }

  .flight-types {
    display: flex;
    background-color: $Cool_Black;
    z-index: 1;
    justify-content: space-between;
    font-size: 16px;
    border: 1px solid $Pure_White;
    border-radius: 22px;
    color: #fff;
    box-sizing: content-box;
    @media (max-width: 600px) {
      justify-content: center;
      width: fit-content;
      margin: auto;
    }
    .switch_label {
      cursor: pointer;
      padding: 10px 20px;
      color: $Pure_White;
    }
    .checked {
      .switch_label {
        cursor: pointer;
        padding: 10px 20px;
        color: $base-color;

        &:before {
          border-radius: 22px;
          background: $Mindaro_Yellow-Green;
        }
      }
    }
  }

  .flight-types .checked.switch_label {
    background-color: $Mindaro_Yellow-Green !important;
    border-radius: 22px;
    color: $Cool_Black;
  }
}

.network_right_drawer {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  .user_picture {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    flex: 0.25 1 0;

    img {
      height: 300px;
      max-width: 270px;
    }
  }

  .user_content {
    flex: 1 1 0;
    h2 {
      font-size: 2.5rem;
      margin: 0 0 1rem 0;
      font-weight: 700;
    }
    p {
      font-size: 16px;
      line-height: 1.2;
      font-weight: 600;
      color: $base-color;
    }

    .text-group > span {
      width: auto !important;
      white-space: pre-wrap;
      word-break: break-word;
    }
  }
  .social_links {
    flex: 1;
    @media (max-width: 567px) {
      flex: none;
    }
    .samll-text {
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 5px;
    }
    .wvp-p-flex-d {
      height: auto !important;

      .social-icon {
        height: 25px !important;
        width: 25px !important;
        object-fit: contain;
      }
    }
  }
}

.grid_column {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1.5rem;
  .grid_card {
    border: 1px solid #d9d9d9;
    padding: 1rem;
    flex: 1;
    width: 100%;
    h2 {
      text-align: center;
      margin-bottom: 1rem;
    }
    .p-1-5 {
      padding: 1rem 5rem !important;
    }
    .grid_list {
      border-bottom: 1.5px solid #d9d9d9;
      padding: 1rem;
      display: flex;
      gap: 1rem;

      p {
        font-weight: 600;
      }
      .avatar_img {
        display: inline-block;
        align-self: flex-start;
      }
      .grid_info {
        display: inline-block;
        align-self: center;
        max-width: 240px;
        word-wrap: break-word;
      }
      .action_icons {
        display: inline-block;
        align-self: flex-end;
      }
    }
  }
}

.app-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;

  .card {
    background-color: #ffffff;
    width: 100%;
    height: auto;
    max-height: 300px;
    border-radius: 6px;
    justify-self: center;
    position: relative;
    transition: 100ms;
    border: 1px solid #dcdcdc;

    .company_logo {
      position: absolute;
      top: 0;
      right: 0%;
      width: 26%;
      height: 26%;
      transform: translate(-20%, 25%);
      object-fit: contain;
      z-index: 1;
    }
    .profile-img {
      position: relative;
      width: 120px;
      height: 120px;
      object-fit: cover;
      border-radius: 50%;
      z-index: 3;
      margin: 10px;
      box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.3);
    }
    .background-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 40%;
      background-color: #eceff1;
      z-index: 0;
      border-radius: 6px 6px 0 0;
    }

    .name {
      font-size: 22px;
      position: absolute;
      right: 20px;
      top: 50%;
      display: inline-block;
      max-width: 180px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
    .flex-bio {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .bio {
        margin: 15px;
      }

      .msg-icon {
        padding-right: 1rem;
        cursor: pointer;
        .anticon {
          font-size: 1.4rem;
        }
      }
    }

    .active {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      transform: scale(1.3);
    }
  }
}

.ant-drawer-right {
  .ant-drawer-content-wrapper {
    padding-top: 73px;
  }
}

//   .main {
//     display: grid;
//     grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
//     grid-gap: 20px;
//     margin: 20px;
//   }

//   .container {
//     background-color: #ECEFF1;
//     width: 100%;
//     height: auto;
//     max-height: 300px;
//     justify-self: center;
//     position: relative;
//     transition: 100ms;
//   }

//   .follow:hover, .icon:hover, .profile-img:hover {
//     cursor: pointer;
//   }

//   .follow {
//     font-size: 14px;
//     width: 50px;
//     color: #ECEFF1;
//     z-index: 3;
//     position: absolute;
//     right: 0;
//     margin: 10px;
//     padding: 8px;
//     border-radius: 5px;
//     background-color: rgba(0,0,0,0.7);
//   }

//   @media only screen and (max-width: 2000px) {
//     .main {
//       grid-template-columns: 1fr 1fr 1fr 1fr;
//     }
//   }

//   @media only screen and (max-width: 1500px) {
//     .main {
//       grid-template-columns: 1fr 1fr 1fr;
//     }
//   }

//   @media only screen and (max-width: 1100px) {
//     .main {
//       grid-template-columns: 1fr 1fr;
//     }
//   }

//   @media only screen and (max-width: 650px) {
//     .main {
//       grid-template-columns: 1fr;
//     }
//   }
