.settings-page {
  .header_settings {
    background-color: $Cool_Black;
    padding: 0.75rem;
    position: sticky;
    top: 65.5px;
    z-index: 10;
    .page-title {
      text-align: left;
      color: #fff !important;
    }
    h4 {
      color: #fff !important;
      text-transform: capitalize;
    }
  }
  .ant-tabs {
    padding: 0.75rem;
  }
  .ant-tabs-nav::before {
    // padding-left: 0.75rem;
    border-bottom: 1px solid $border-color;
  }

  .flex_wraper_conn {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;
    @media (max-width: 769px) {
      flex-direction: column;
      gap: 1rem;
      margin: 1rem 0.75rem;
    }
    @media only screen and (min-width: 600px) and (max-width: 900px) {
      flex-direction: column;
      gap: 1rem;
      margin: 1rem 0.75rem;
    }
    @media (max-width: 567px) {
      flex-direction: column;
      gap: 1rem;
    }

    .estb_content_wrapper {
      flex: 1 0 65%;
      @media only screen and (min-width: 600px) and (max-width: 900px) {
        width: 100%;
      }
      @media (max-width: 567px) {
        width: 100%;
      }

      b {
        color: $Pure_White;
      }
    }

    .view-page {
      width: 100%;
      box-shadow: 0 0 0px 1px $border-color;
      background-color: $Cool_Black;
      min-height: 320px;
      border-radius: 6px;
      margin: 0;
      padding: $smallsize;
      display: flex;
      flex-direction: column;
      margin-left: auto;
      @media (max-width: 769px) {
        max-width: 100% !important;
      }
      @media only screen and (min-width: 600px) and (max-width: 900px) {
        max-width: 100% !important;
      }
      @media (max-width: 567px) {
        margin-bottom: 3rem;
      }
      .view_info {
        text-align: left !important;

        h3 {
          margin-top: 0px !important;
          color: $Pure_White;
        }

        .ant-form-item {
          margin-bottom: 1rem !important;
        }
      }

      .label-group {
        padding-bottom: $smallsize;

        label {
        }

        p {
          margin: 0;
          color: $base-color;
        }
      }

      .btn-more {
        text-align: center !important;
        border-width: 3px;
        border-color: $Mindaro_Yellow-Green;
        color: $Cool_Black;
        background-color: $Mindaro_Yellow-Green;
        margin-top: auto;
        width: 100%;
      }
    }
  }

  .list_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 746px;
    width: 100%;
    margin-bottom: 76px;

    ul {
      margin-top: 2rem;
      padding-left: 18px;
      max-width: 380px;
      position: relative;

      li {
        color: #000000;
        padding-left: 0 !important;
        line-height: 1.8;
        font-size: 0.875rem;
      }
    }
  }
}

.plan_info {
  padding: 1rem;
}

.setting_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  @media (max-width: 600px) {
    justify-content: flex-start;
  }
  .web_add_btn {
    @media (max-width: 600px) {
      display: none;
    }
  }
  .soft_green_title {
    padding-bottom: 0;
  }
}
.ms_add_btn {
  display: none;

  @media (max-width: 600px) {
    display: block;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    bottom: 69px;
    padding: 16px;
    background: $Cool_Black;
    z-index: 10;
  }
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  .popup-content {
    background: $Cool_Black;
    border: 1px solid $light_Black;
    padding: 30px;
    border-radius: 10px;
    width: 565px;
    max-width: 100%;
    color: #fff;
    .invite_form {
      display: flex;
      flex-direction: column;
      gap: 21px;
    }
    .popup-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 1rem;
    }
  }

  h2 {
    margin-top: 0;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .form-group label {
    display: block;
    margin-bottom: 5px;
    color: $Pure_White;
  }

  .form-group input,
  .form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid $field_stroke;
    border-radius: 4px;
    background-color: $light_Black;
    color: #fff;

    &::placeholder {
      color: $input_placeholder;
    }
  }

  .form-actions {
    display: flex;
    justify-content: space-between;
    gap: 16px;

    .ant-form-item {
      width: 100%;
    }
  }

  .cancel-btn {
    width: 100%;
    margin-right: 1rem;
  }

  .invite-btn {
    width: 100%;
  }
}

.anticon-mail {
  color: $Mindaro_Yellow-Green;
}
