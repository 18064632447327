.layout-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  gap: 1.5rem;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 600px) {
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    padding-bottom: 100px;
  }

  .INVESTOR {
    .card-header {
      height: 150px;
    }
    .card-info {
      .entity-details {
        display: none !important;
      }
    }
  }

  .COMPANY,
  .REALESTATE {
    .card-info {
      .entity-description {
        display: none !important;
      }
    }
  }

  .main-card {
    min-height: auto;
    position: relative;
    border-radius: 8px;
    background: #191b1e;
    text-transform: capitalize;
    height: 100%;

    .card-header {
      position: relative;
      padding: 16px;
      background: #1e2225;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #23272b;
      align-items: center;
      gap: 8px;
      border-radius: 8px 8px 0 0;
      .new_tag {
        padding: 6px 10px;
        border-radius: 6px;
        background-color: $Celadon_Green;
        color: $Cool_Black;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        position: absolute;
        top: 10px;
        right: 10px;
        box-shadow: 0px 0.72px 1.44px 0px #1018280d;
        display: none;
      }
      .container-img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 3px solid $Pure_White;
        background: $Pure_White;

        @media (max-width: 600px) {
        }
        .color_card {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background: $Pure_White;
          display: flex;
          align-items: center;
          justify-content: center;
          .d-first_letter {
            font-size: 0px;
            &::first-letter {
              font-size: 1.875rem;
              text-align: center;
              font-weight: 400;
              color: $Cool_Black;
            }
          }
        }
        .company-logo {
          object-fit: cover;
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      .header_content {
        max-width: 100%;
        h2 {
          text-transform: capitalize;
          text-overflow: ellipsis;
          text-align: center;
          font-family: $work-sans_font;
          white-space: nowrap;
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 600;
          line-height: 1.875rem;
          max-width: 180px;
          color: $Pure_White;
          overflow: hidden;
          display: block;
          @media (max-width: 600px) {
            max-width: 300px;
          }
        }

        p {
          overflow: hidden;
          display: block;
          color: $Pure_White;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-family: $work-sans_font;
          font-size: 0.875rem;
          text-align: center;
          font-style: normal;
          font-weight: 400;
          line-height: 1.25rem;
          max-width: 180px;
          @media (max-width: 600px) {
            max-width: 300px;
          }
        }
      }
    }

    .info-summary {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      margin-bottom: 1.5rem;
      position: relative;
      overflow: hidden;
      min-height: 300px;
      max-height: 300px;
      .frame {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-self: stretch;
        width: 100%;
        position: relative;
        flex: 0 0 auto;
      }

      .tag_content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        align-self: stretch;
        width: 100%;
        position: relative;
        flex: 0 0 auto;
        height: 136px;
        max-height: fit-content;
      }
      .text-wrapper {
        position: relative;
        width: fit-content;

        font-family: $work-sans_font;
        font-weight: 600;
        color: $gray_color;
        font-size: 14px;
        letter-spacing: 0px;
        line-height: 20px;
        white-space: nowrap;
        font-style: normal;
      }
      .frame-2 {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 8px 8px;
        align-self: stretch;
        width: 100%;
        position: relative;
      }
      .div-wrapper {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 4px 8px;
        background-color: $light_Black;
        border-radius: 4px;
        position: relative;
        flex: 0 0 auto;
      }
      .text-wrapper-2 {
        position: relative;
        width: fit-content;
        font-family: $work-sans_font;
        font-weight: 500;
        color: $Pure_White;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 24px;
        text-overflow: ellipsis;
        max-width: 240px;
        overflow: hidden;
        white-space: nowrap;
        font-style: normal;
      }
    }

    .card-content {
      padding: 1rem;
      background: #191b1e;
      border-radius: 0 0 8px 8px;

      h2 {
        text-transform: capitalize;
        text-overflow: ellipsis;
        font-family: $work-sans_font;
        white-space: nowrap;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.875rem;
        max-width: 240px;
        color: $Pure_White;
        overflow: hidden;
        display: block;
        padding-bottom: 0.5rem;
        @media (max-width: 600px) {
          max-width: 300px;
        }
      }
      .card-info {
        .entity-details {
          display: flex;
          text-align: left;
          flex-direction: column;
          justify-content: flex-start;
          min-height: 185px;
          max-height: 185px;
          overflow: hidden;
          text-transform: capitalize;
          margin-bottom: 1.5rem;

          @media (max-width: 567px) {
            height: auto;
          }
          .frame {
            display: flex;
            flex-direction: column;
            gap: 8px;
          }
          .list-info {
            font-family: $work-sans_font;
            display: flex;
            justify-content: space-between;
            .list_label {
              width: 60%;
              font-weight: 500;
              line-height: 1.25rem;
              font-size: 0.875rem;
              color: $gray_color;
            }

            .list_value {
              font-weight: 400;
              width: 100%;
              line-height: 1.25rem;
              font-size: 0.875rem;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              color: $Pure_White;
              flex: 1;

              .sub-value {
                font-size: 0.875rem;
              }

              @media (max-width: 567px) {
                max-width: 180px;
              }
            }
          }

          p {
            color: $base-color;
            font-size: $font-size;
            margin: 4px 0 12px 0;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 140px;
            overflow: hidden;
            display: inline-block;

            &:last-child {
              margin-bottom: 1.5rem;
            }
          }
        }
      }

      .entity-description {
        color: $Pure_White;
        min-height: 77px;
        word-break: break-word;
        text-transform: none;
        margin: 0 !important;

        .multine-ellipsis {
          margin: 0 0;
        }
      }

      .secondary_btn {
        background-color: $Cool_Black;
        color: $Sand !important;
        border: 1px solid $border-color;
        text-align: center;
        width: 100%;
        font-size: 1.125rem;

        &:hover {
          background-color: $Mindaro_Yellow-Green !important;
          color: $Cool_Black !important;
          border-color: $Mindaro_Yellow-Green !important;
        }
      }

      .conn_status {
        text-align: center;
        font-size: 1rem;
        color: $Sand;
        text-transform: capitalize;
        line-height: 40px;

        .ant-btn-default {
          padding: 0rem 5.5rem;
          font-weight: 500;
          line-height: 28px;
          font-size: 1rem;
          width: 100%;

          &:hover {
            background-color: $Mindaro_Yellow-Green !important;
            color: $Cool_Black !important;
            border: 1px solid $Mindaro_Yellow-Green !important;
          }
        }

        a {
          cursor: pointer;
          line-height: 40px;
        }
      }
    }
  }
}
.no-tag-sections {
  .entity-description {
    display: block !important;

    .multine-ellipsis {
      -webkit-line-clamp: 14;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}
.tag-sections-1 {
  .entity-description {
    display: block !important;
    .multine-ellipsis {
      -webkit-line-clamp: 7;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.tag-sections-2 {
  .entity-description {
    display: none !important;
  }
}

.tag-sections-3 {
  .entity-description {
    display: none !important;
  }
}

.layout-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
  justify-content: space-between;
  width: 100%;
  scroll-behavior: smooth;

  @media (max-width: 600px) {
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    padding-bottom: 100px;
  }
  .main-card {
    // border: 1px solid $light_Black;
    min-height: auto;
    position: relative;
    border-radius: 10px;
    // background: #1E2225;
    // background: $Card_bg_color;
    text-transform: capitalize;
    height: 100%;
    // padding-bottom: 1rem;

    @media (max-width: 567px) {
      // min-height: 300px;
    }
    .card-sub-header {
      display: flex;
      gap: 0.5rem;
      position: relative;
      flex-direction: column;

      .container-img {
        width: 100% !important;
        height: 274px !important;
        border: none !important;

        .company-logo {
          object-fit: cover !important;
          width: 100%;
          height: 100%;
          border-radius: 8px 8px 0 0;
        }
      }
    }
  }
}

.color1 {
  background-color: $Periwinkle !important;
}

.color2 {
  background-color: $Celadon_Green !important;
}

.color3 {
  background-color: $new_Sand !important;
}

.layout-grid {
  .main-card.COMPANY {
    .card-header {
      padding: 0px;
      .container-img {
        width: 100%;
        height: 175px;
        border-radius: 8px 8px 0 0;
        border: none;

        @media (max-width: 600px) {
        }
        .color_card {
          width: 100%;
          height: 175px;
          border-radius: 8px 8px 0 0;
          display: flex;
          align-items: center;
          justify-content: center;
          h2 {
            font-weight: 700;
            color: $Cool_Black;
            font-size: 32px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 250px;
            font-family: $spectral_font;
            &::first-letter {
              font-size: 32px;
              font-weight: 700;
            }
          }
        }
        .company-logo {
          object-fit: cover;
          width: 100%;
          height: 100%;
          border-radius: 8px 8px 0 0;
        }
      }

      .header_content {
        display: none;
      }
    }
  }
}

.layout-grid {
  .main-card.INVESTOR {
    .card-content {
      h2 {
        display: none !important;
      }
    }
  }
}

.layout-grid {
  .main-card.REALESTATE {
    .card-header {
      padding: 0px;
      .container-img {
        width: 100%;
        height: 175px;
        border-radius: 8px 8px 0 0;
        border: none;

        @media (max-width: 600px) {
        }
        .color_card {
          width: 100%;
          height: 175px;
          border-radius: 8px 8px 0 0;
          display: flex;
          align-items: center;
          justify-content: center;
          h2 {
            font-weight: 700;
            color: $Cool_Black;
            font-size: 32px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 250px;
            font-family: $spectral_font;
            &::first-letter {
              font-size: 32px;
              font-weight: 700;
            }
          }
        }
        .company-logo {
          object-fit: cover;
          width: 100%;
          height: 100%;
          border-radius: 8px 8px 0 0;
        }
      }

      .header_content {
        display: none;
      }
    }
  }
}
