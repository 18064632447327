/* .ant-list-item:hover {
  box-shadow: 0 0 4px #C78F11;
}

.active-list-item {
  background-color: #fff !important;
  box-shadow: 0 0 4px #C78F11;
}

.chat-user-icon {
  max-height: 150px;
} */

.loader-line {
  width: 100%;
  height: 5px;
  position: absolute;
  top: 0;
  overflow: hidden;
  background-color: #ddd;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  z-index: 9999;
}

.loader-line:before {
  content: '';
  position: absolute;
  left: -50%;
  height: 5px;
  width: 40%;
  background-color: coral;
  -webkit-animation: lineAnim 1s linear infinite;
  -moz-animation: lineAnim 1s linear infinite;
  animation: lineAnim 1s linear infinite;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

@keyframes lineAnim {
  0% {
    left: -40%;
  }
  50% {
    left: 20%;
    width: 80%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
}
