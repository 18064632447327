@media (max-width: 600px) {
  .showw {
    display: block;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .hidee {
    display: none;
  }

  .no-chat {
    display: none;
  }
}

.no-chat {
  display: block;
}

.chat_row {
  .ant-col {
    // background: $Card_bg_color;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .no_conversations {
    color: $Pure_White;
    padding: 1rem;
    text-align: center;
  }
}

.chat_header {
  display: flex;
  align-items: center;
  padding: 16px 24px;
  gap: 4px;

  .back_icon {
    display: none;

    @media (max-width: 600px) {
      display: block;
    }
  }
  .chat_user_content {
    display: flex;
    gap: 0.75rem;
    align-items: center;
  }

  .convo-avatar {
    .color_card {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      text-align: center;

      h2 {
        font-weight: 500;
        color: $Cool_Black;
        line-height: 45px;
      }
    }
    .ant-avatar {
      // background-color: $Soft_Emerald;
      // border: 1px solid $Sand;

      .anticon {
        color: $Pure_White !important;
      }
    }
  }

  .chat_title.sub_header_title {
    h1 {
      color: $Pure_White !important;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;

      @media (max-width: 600px) {
        text-overflow: ellipsis;
        max-width: 220px;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  .chat_sub_title {
    display: block;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: $gray_color;
    max-width: 260px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    @media (max-width: 600px) {
      max-width: 220px;
    }
  }
}

.new_msg {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: $Soft_Emerald;
  position: absolute;
  top: 40px;
  right: 30px;
}

.messagesContainer {
  // position: sticky;
  // top: 112px;
  // z-index: 10;
  @media (max-width: 600px) {
    padding-bottom: 100px;
  }
  .search_header {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #0c0e12;
    @media (max-width: 600px) {
    }

    .badge {
      background-color: $Pure_White;
      color: $Cool_Black;
      border-radius: 26px;
      padding: 4px 8px;
      font-size: 12px;
      font-weight: 700;
      line-height: 14px;
    }
  }

  .tabs_links {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 1rem;
    border: 1px solid $Pure_White;
    border-radius: 26px;

    .tab_link {
      color: $Pure_White;
      padding: 10px 20px;
      text-align: center;
      font-size: 1.125rem;
      cursor: pointer;
      font-weight: 400;
      border-radius: 26px;
      flex: 1;
    }

    .tab_active {
      background: $Mindaro_Yellow-Green;
      color: $Cool_Black;
    }
  }

  .searchContainer {
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;
  }

  .searchInput {
    width: 90%;
    height: 40px;
    padding: 0 16px;
    border-radius: 4px;
    margin: auto;
    border: 1px solid $Pure_White;
    background-color: transparent !important;
    color: $Pure_White;
  }

  .searchInput::placeholder {
    color: $Pure_White;
  }
}

.chat_lists {
  overflow: auto;
  height: calc(-211px + 100dvh);

  @media (max-width: 600px) {
    height: calc(-200px + 100dvh);
  }

  .hasunread {
    .user-title {
      font-weight: 600 !important;
    }
    .time_status {
      color: $Pure_White !important;
      font-weight: 600 !important;
    }
  }
  .hasread {
    .user-title {
      font-weight: 500 !important;
    }
  }
  .chat_card {
    padding: 12px 16px 12px 16px;
    position: relative;
    border-bottom: 1px solid #1e2225;
    &:hover {
      background: rgba(94, 96, 113, 0.1);
    }

    .chat_body {
      width: 100%;
      display: flex;
      align-items: flex-start;
      gap: 12px;

      .convo-avatar {
        display: inline-block;

        .ant-avatar {
          background-color: $Soft_Emerald !important;
          border: none;
        }
        .color_card {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          text-align: center;

          h2 {
            font-weight: 500;
            color: $Cool_Black;
            line-height: 45px;
          }
        }
        svg {
          fill: $Pure_White;
        }
      }

      .user_content {
        .usergroup_icon {
          position: absolute;
          right: 100px;
          top: 10px;
          display: inline-block;

          .anticon-usergroup-add {
            font-size: 14px;
            color: $Pure_White;
          }
        }
      }
    }
  }
}

.chat_card.active {
  background: #5e60714d;
}

.infinite-scroll-component::-webkit-scrollbar {
  width: 4px;
  border-radius: 26px;
  display: none;
}

.infinite-scroll-component::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: transparent;
  border-radius: 26px;
}

.infinite-scroll-component::-webkit-scrollbar-thumb {
  border: 4px solid transparent;
  border-radius: 26px;
}

.infinite-scroll-component {
  padding: 0 0.75rem;
}

.left-msg button {
  display: none;
}

.left-msg .rounded-full {
  // position: absolute;
  top: 0;
}

.right-msg button {
  display: none;
}

.right-msg .rounded-full {
  // position: absolute;
  top: 0;
}

.chat-from {
  display: flex;
  flex-direction: column;
  padding: 16px 12px;
}

input {
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: $Pure_White;
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: $work-sans_font;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-color: $light_Black;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  transition: all 0.2s;
}

.no_messages {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: calc(-130px + 100vh);

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    background: url('../../../../../public/assets/no-messages.png') no-repeat;
    background-size: contain;
    width: 100px;
    height: 100px;
    transform: translate(-50%, -50%);
  }

  span {
    color: $Pure_White;
    font-size: 1rem;
    padding-top: 8rem;
  }
}

.no_msg_status {
  text-align: center;
  padding: 4rem 0;

  .CommentOutlined {
    .anticon-comment {
      font-size: 5rem;
      color: $Soft_Emerald;
    }
  }

  h1 {
    line-height: 4;
  }

  p {
    padding: 0 2rem;
  }
}

.popup_message {
  background: $Cool_Black;
  border-radius: 10px;
  width: 564px !important;
  border: 1px solid $light_Black !important;
  padding-bottom: 0;

  .ant-modal-content {
    border: none !important;
    border-radius: 10px;
    background: $Cool_Black;
    .ant-modal-title {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 450px;
      font-size: 20px !important;
      line-height: 30px;
      font-weight: 500 !important;
      @media (max-width: 600px) {
        max-width: 250px;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
      }
    }
  }

  .paragraph {
    color: $Celadon_Green !important;
    padding: 1rem 0;
    font-size: 16px;
    line-height: 24px;
    @media (max-width: 600px) {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
    }
  }

  .request_text_box {
    width: 100%;
    height: 100px;

    label {
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      padding-top: 10px;
    }

    textarea {
      height: 100%;
      width: 100%;
      resize: none;
      color: $Pure_White;
      border-radius: 8px;
      border: 1px solid $field_stroke;
      background-color: transparent !important;
      font-size: 1rem;
      accent-color: $Pure_White;
      padding: 0.5rem;
      font-weight: 400;
      margin: 0.5rem 0;

      &:focus {
        outline: none;
      }
    }
  }

  .ant-modal-footer {
    margin-top: 5rem;

    .ant-btn-default {
      border: 1px solid $Pure_White !important;

      &:hover {
        color: $Cool_Black !important;
        border: 1px solid $Mindaro_Yellow-Green;
      }
    }
  }
}

// messages screen

.chat_window.PENDING {
  .chating_screen {
    min-height: unset !important;
    height: unset;
  }
}

.chat_window {
  // padding: 0 16px;

  .chating_screen {
    height: calc(-311px + 100dvh);
    overflow: auto;
    padding: 0 0.75rem;
    display: flex;
    flex-direction: column-reverse;

    @media (max-width: 600px) {
      height: calc(-245px + 100dvh);
    }

    .chat-msg {
      color: $Pure_White;
      font-size: 1rem;
    }

    .left-msg {
      display: flex;
      justify-content: left;
      margin: 8px 0;

      .convo-avatar {
        .color_card {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          text-align: center;

          .d-first_letter {
            font-weight: 500;
            color: $Cool_Black !important;
            line-height: 45px;
          }
        }
        .ant-avatar {
          .anticon {
            color: $Pure_White !important;
          }
        }
      }

      .chat-text {
        background-color: $light_Black;
        border-radius: 4px;
        padding: 8px 12px 8px 12px;
        flex: 1;
        min-width: 0;
        max-width: fit-content;
        margin-right: auto;
        position: relative;

        span {
          max-width: calc(100% - 67px);
          overflow-wrap: break-word;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-wrap: balance;
          color: $Pure_White;
        }
      }

      .chat-status {
        font-size: 12px;
        line-height: 18px;
        color: $gray_color;
        padding-top: 10px;
        margin-right: auto;
      }
    }

    .right-msg {
      display: flex;
      flex-direction: column;
      justify-content: right;
      margin: 8px 0;
      .convo-avatar {
        display: none;
      }
      .chat-text {
        background-color: #b2b2b3;
        border-radius: 4px;
        padding: 8px 12px 8px 12px;
        min-width: 0;
        max-width: fit-content;
        margin-left: auto;

        span {
          max-width: calc(100% - 67px);
          overflow-wrap: break-word;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-wrap: balance;
          color: $Cool_Black;
        }
      }

      .chat-status {
        font-size: 12px;
        color: $gray_color;
        padding: 0.5rem 0;
        margin-left: auto;
        text-align: end;
      }
    }
  }
}

.chat-input-div {
  position: relative;
  padding: 24px;

  @media (max-width: 600px) {
    padding: 12px;
    display: block;
    position: sticky;
    bottom: 0px;
  }

  .text-input {
    box-sizing: border-box;
    width: calc(100% - 0px);
    resize: none;
    outline: none;
    padding: 8px 12px;
    border-radius: 4px;
    overflow: auto;
    overflow-y: auto;
    overflow-x: hidden;
    font-size: 1rem;
    color: $Pure_White;
    text-wrap: wrap;
    transition: height 1s;
    font-family: $work-sans_font;
    @media (max-width: 600px) {
      width: 100%;
    }
    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #f5f5f5;

      &:hover {
        background: #ddd;
      }
    }
  }

  .text-input:focus-visible {
    border: 1px solid $border-color !important;

    &::placeholder {
      visibility: hidden;
    }
  }

  .lucide_send_icon {
    position: absolute;
    top: 35px;
    right: 40px;

    @media (max-width: 600px) {
      top: 23px;
      right: 30px;
    }
  }
}

.chat-convo-window {
  height: 400px;
  width: 250px;
  position: fixed;
  bottom: 0px;
  right: 0px;
}

.chat-convo-window {
  .infinite-scroll-component {
    padding: 0 0.15rem !important;
    height: 300px !important;
  }
}

.chat_window {
  .infinite-scroll-component__outerdiv {
    .infinite-scroll-component {
      height: unset !important;
    }
  }
}

.conversations-window {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 563px);
  flex: 0 0 288px;
  width: 320px;
  min-width: 0;
  min-height: 410px;
  background: $Cool_Black;
  border-radius: 4px 4px 0 0;
  box-shadow:
    0px 0px 0px 1px hsla(0, 0%, 54.9%, 0.2),
    0px 4px 4px rgba(0, 0, 0, 0.3);
  transform: translateY(0px);
  transition-duration: 167ms;
  animation: fade-in 167ms cubic-bezier(0.4, 0, 1, 1);
  position: fixed;
  bottom: 0;
  right: 10px;
  z-index: 10;

  .chat_title {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    border-bottom: 1px solid $border-color;
    padding: 10px 12px;
    gap: 0.5rem;
  }
}

.conversations-window.minimized {
  /* transform: translateY(355px); */
  min-height: 0;
  height: 29px;
}

.request_div {
  display: flex;
  flex-direction: column-reverse;
  @media (max-width: 600px) {
    // padding-bottom: 100px;
  }

  .request_section {
    height: calc(-311px + 100dvh);
    overflow: auto;

    @media (max-width: 600px) {
      height: unset;
      overflow: auto;
    }

    .request_user {
      padding: 0 0.75rem;
      overflow: auto;

      .send_message {
        background-color: $light_Black;
        color: $Pure_White;
        padding: 0.75rem;
        border-radius: 10px;

        b {
          color: $Pure_White;
        }
      }

      .req_profile_card {
        margin-top: 1rem;
        max-width: 100%;
        border: 1px solid $border-color;
        border-radius: 10px;
        display: flex;
        gap: 0.75rem;
        @media (max-width: 1024px) {
          max-width: 100%;
          flex-direction: column;
        }
        @media (max-width: 600px) {
          max-width: 100%;
          flex-direction: column;
        }

        .card_profile {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          border-radius: 10px 0px 0px 10px;
          flex: 1 1 50%;
          @media (max-width: 1024px) {
            width: 100%;
            min-height: 100%;
            flex: 1;
            border-radius: 10px 10px 0px 0px;
          }
          @media (max-width: 600px) {
            width: 100%;
            min-height: 100%;
            flex: 1;
            border-radius: 10px 10px 0px 0px;
          }

          .card_img {
            width: 100%;
            height: 100%;
            border-radius: 10px 0px 0px 10px;
            @media (max-width: 1024px) {
              width: 100%;
              border-radius: 10px 10px 0px 0px;
            }
            @media (max-width: 567px) {
              width: 100%;
              border-radius: 10px 10px 0px 0px;
            }
          }
        }

        .req_card {
          display: flex;
          justify-content: center;
          flex-direction: column;
          gap: 1rem;
          min-height: 140px;
          padding: 1rem;
          flex: 1 1 50%;

          @media (max-width: 600px) {
            flex-direction: column;
            justify-content: center;
          }

          .header_title {
            font-size: 24px;
            color: $Pure_White;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: -0.64px;
            max-width: 280px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          .table_list {
            .text-group {
              padding: 6px 0;
              border-bottom: solid 1px $light_Black;
              font-size: 18px;
              color: $Pure_White;
              font-family: $work-sans_font;
              display: flex;
              align-items: center;
              justify-content: space-between;

              @media (max-width: 600px) {
                font-size: 14px;
              }
              > label {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
                font-weight: 500 !important;
                line-height: 28px;
                max-width: 180px;
                @media (max-width: 600px) {
                  max-width: 160px;
                  line-height: 24px;
                }
              }

              > span {
                padding-left: 10px;
                white-space: nowrap;
                overflow: hidden;
                font-weight: 400 !important;
                text-overflow: ellipsis;
                display: inline-block;
                line-height: 28px;
                max-width: 180px;
                @media (max-width: 600px) {
                  max-width: 160px;
                  line-height: 24px;
                }

                b {
                  color: $Pure_White;
                }
              }
            }
          }
        }

        .entity-descriotion {
          -webkit-line-clamp: 3;
          line-clamp: 3;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          color: $Periwinkle;
          font-size: 18px;
          font-weight: 400;
          line-height: 28px;
          margin: 0 !important;
          word-break: break-word;
        }

        .entity-description {
          text-align: left !important;
          word-break: break-word;
        }
      }

      .btn_secondary {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
        width: 100%;
        margin: auto auto 0 auto;
        a {
          width: 100%;
        }
        @media (max-width: 567px) {
          align-items: center;
          justify-content: center;
          margin-bottom: 0.5rem;
          gap: 1.5rem;
        }

        .ant-btn {
          width: 100%;
          padding: 5px 2rem !important;
        }
      }

      .anticon-login,
      .anticon-logout {
        display: none;
      }
    }

    .pending_msg {
      font-size: 14px;
      color: $Sand;
      padding: 1rem;
      text-align: center;
    }

    .chat_actions_btn {
      margin: 1rem 1rem 2rem 1rem;
      text-align: center;
      @media (max-width: 600px) {
        display: flex;
        flex-direction: column-reverse;
        gap: 1rem;
      }

      .btn {
        border: 1px solid $Pure_White;
        background-color: transparent;
        color: $Pure_White;
        font-size: 14px;
        margin-right: 0.75rem;
        border-radius: 3px;
        cursor: pointer;
        &:hover {
          color: $Cool_Black;
        }
      }

      .btn_accept {
        background-color: $Mindaro_Yellow-Green;
        color: $Cool_Black;
        border: 1px solid $Mindaro_Yellow-Green;
      }
    }
  }
}

.my-request {
  .left-user-list {
    .ant-card-body {
      opacity: 0.4;
      border-radius: 4px;
      pointer-events: none;
      background-color: $border-color;
    }
  }
}

@media (max-width: 767px) {
  .mv-no-convo {
    .messagesContainer {
      display: block;

      // .chat_card.active {
      //   border: 1px solid $border-color !important;
      // }
    }

    .chat_view {
      display: none;
    }

    .no_messages {
      display: none;
    }
  }

  .mv-convo-selected {
    .messagesContainer {
      display: none;
    }

    .chat_view {
      display: block;
    }
  }
}

.slim_scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.slim_scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.slim_scrollbar::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.slim_scrollbar::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

//VMC

.messageContainer {
  color: #fff; /* Set text color to white */
  font-size: 16px; /* Adjust font size as needed */
  font-weight: 400;
}

.company-details {
  h2 {
    font-weight: 600;
  }
  .list_group {
    padding: 0 12px 0px 12px;

    @media (max-width: 600px) {
      padding: 0;
    }
    .text-group {
      padding: 6px 0;
      border-bottom: solid 1px $light_Black;
      font-size: 18px;
      color: $Pure_White;
      font-family: $work-sans_font;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 600px) {
        font-size: 14px;
      }
      > label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        font-weight: 500 !important;
        line-height: 28px;
        max-width: 260px;
        @media (max-width: 600px) {
          max-width: 160px;
          line-height: 24px;
        }
      }

      & > span {
        padding-left: 10px;
        white-space: nowrap;
        overflow: hidden;
        font-weight: 400 !important;
        text-overflow: ellipsis;
        display: inline-block;
        line-height: 28px;
        max-width: 260px;
        @media (max-width: 600px) {
          max-width: 160px;
          line-height: 24px;
        }

        b {
          color: $Pure_White;
        }
      }
    }
  }
}

.message-details {
  .popup_title {
    text-align: center;
    font-size: 16px;
    max-width: 100%;
  }
}

.message-details h2,
.message-details h3,
.message-details h4 {
  margin: 0 0 15px 0;
}

.company-profile-link {
  text-transform: capitalize;
  display: block;
  text-align: center;
  margin: 20px 0;
  color: $Mindaro_Yellow-Green !important;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  padding-bottom: 2px;
  position: relative;
}

.members {
  margin-top: 20px;
  h4 {
    font-weight: 600;
  }

  .members_list {
    max-height: 26vh;
    overflow: auto;
    .convo-avatar {
      .color_card {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        text-align: center;

        h2 {
          font-weight: 500;
          color: $Cool_Black;
          line-height: 45px;
        }
      }
    }
  }
}

.member {
  display: flex;
  align-items: center;
  margin: 12px 0;
  gap: 1rem;
}

.member img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.member-info {
  display: flex;
  flex-direction: column;
}

.member-name {
  font-weight: 500;
  color: $Pure_White;
  font-size: 16px;
  line-height: 24px;
}

.member-role {
  font-size: 14px;
  color: $gray_color;
  font-weight: 400;
  line-height: 20px;
}

.msgactive {
  svg {
    path {
      stroke: #fff;
    }
  }
}

.isdisabled {
  .chat_header {
    opacity: 0.4;
    pointer-events: none;
  }
  .chating_screen {
    opacity: 0.4;
    pointer-events: none;
  }
  .chat-msg {
    opacity: 0.4;
    pointer-events: none;
  }
  textarea {
    display: none;
  }
  .lucide_send_icon {
    display: none;
  }
}
